<template>
  <button id="resbutton" class="btn btn-unidark btn-import py-2" type="button" @click="getLocationFilterOption()" data-bs-toggle="modal" data-bs-target="#resModal"> {{$t('reservation.get')}} </button>
  <Overview 
        :moduleName="$tc(navName, 1)"
        :moduleNamePlural="$tc(navName, 2)"
        sort="date"
        :sortOrder=-1
        :selectFields=selectFields
        :filterOptions="filterOptions"
        :itemService=itemService
        :itemFormRef=itemFormRef
        ref="overviewref"
    >
        <template #columns>
            <Column field="date" :header="$t('overview.date')" :sortable="true" style="width: 120px">
              <template #body="slotProps">
                {{formatDate(slotProps.data.date, 'shortDate')}}
              </template>
            </Column>
            <Column field="external_id" header="ID" style="width: 100px">
              <template #body="slotProps">
                  <div class="double-up">{{slotProps.data.external_id}}</div>
                  <!-- <div class="double-up text-gray fs-13">{{slotProps.data.id}}</div> -->
              </template>
            </Column>
            <Column field="location.full_name" :header="$t('navigation.locations')"></Column>
            <Column field="relation_organisation" :header="$t('overview.relation')">
              <template #body="slotProps">
                <template v-if="slotProps.data.relation_organisation">
                  <div class="double-up">{{slotProps.data.relation_organisation}}</div>
                  <div class="double-up text-gray fs-13">{{slotProps.data.relation_name}}</div>
                </template>
                <div v-else>{{slotProps.data.relation_name}}</div>
              </template>
            </Column>
            <Column field="name" :header="$t('overview.event')"></Column>
            <Column field="persons" :header="$t('overview.amount')" style="width: 100px"></Column>
            <Column field="status" :header="$t('overview.status')" :sortable="false" style="width: 150px; text-align: center">
              <template #body="slotProps">
                  <div class="text-center" v-html="getStatus(slotProps.data)"></div>
              </template>
            </Column>
        </template>
    </Overview>

    <div class="modal fade" id="resModal" tabindex="-1" aria-labelledby="resLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="resLabel">{{$t('reservation.get')}}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="p-2">
                    <FormItem id="reslocation" type="livesearch" v-model="resLocation.id" :label="$t('navigation.locations')" :service="locationService" :content="resLocation.name ? resLocation.name : ''" />
                </div>
                <div class="modal-footer d-flex">
                    <button type="button" class="btn btn-unidark" data-bs-dismiss="modal" @click.prevent="getReservations" :disabled="!resLocation.id">Ophalen</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from 'primevue/column';
import resService from '@/services/ReservationService'
import csService from '@/services/CustomerService'

export default {
    components: {
        Overview,
        Column
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$refs.overviewref.getIndex(from);
        })
    },
    data() {
        return {
            item: {},
            itemService: resService,
            locationService: csService,
            itemFormRef: null,
            navName: 'navigation.reservations',
            companyTypes: [],
            selectFields: [
              'id',
              'status',
              'external_id',
              'location_id',
              'name',
              'relation_organisation',
              'persons',
              'relation_name',
              'date'
            ],
            filterOptions: [
                {
                    field: 'location_id',
                    service: csService,
                    nameField: 'full_name',
                    name: "Locaties",
                    multiple: true
                }
            ],
            resLocation: {id: null, name: null}
        }
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;
    },
    methods: {
      getStatus(data){
        //if(!data.status) return '';
        const statuses = [
          {"name": "confirmed", "class": "bg-secondary text-light", "inner": "<i class=\"far fa-clock\"></i> bevestigd"},
          {"name": "active", "class": "bg-unidark text-light", "inner": "<i class=\"far fa-clock\"></i> actief"},
          {"name": "active_warning", "class": "bg-warning text-light", "inner": "<i class=\"far fa-warning\"></i> actief"},
          {"name": "active_error", "class": "bg-danger text-light", "inner": "<i class=\"far fa-warning\"></i> actief"},
          {"name": "usage", "class": "bg-unidark text-light", "inner": "<i class=\"far fa-clock\"></i> verbruik"},
          {"name": "usage_warning", "class": "bg-warning text-light", "inner": "<i class=\"far fa-warning\"></i> verbruik"},
          {"name": "usage_error", "class": "bg-danger text-light", "inner": "<i class=\"far fa-warning\"></i> verbruik"},
          {"name": "ok", "class": "bg-success text-light", "inner": "<i class=\"far fa-check default-color\"></i> OK"},
        ];
        //get status by name
        let status = statuses.find(s => s.name === data.status);
        if(!status) status = statuses[1];
        return `<span class="pill status-pill ${status.class}">${status.inner}</span>`;
      },
      getLocationFilterOption(){
        if(!this.$refs.overviewref) return false;
        this.resLocation = {id: null, name: null};
        const overviewFilter = sessionStorage.getItem('filter_Reserveringen') ? JSON.parse(sessionStorage.getItem('filter_Reserveringen')) : this.$refs.overviewref.filterOptions;
        const locationOption = overviewFilter.find(o => o.field === 'location_id' && o.value.length > 0 && o.active);
        if(!locationOption) return false;
        this.resLocation = locationOption.value[0];
        console.log(locationOption.value[0]);
      },
      getReservations(){
        resService.getReservations(this.resLocation.id).then((response) => {
          this.resLocation = {id: null, name: null};
          console.log("Response", response);
          if(response.data.success)
            this.toastSuccess(response.data.message);
          else
            this.toastError(response.data.message);
        });
      }
    }
    
}
</script>
<style scoped>
  .double-up{
    line-height: 1.25rem
  }
  #resbutton{
    position: absolute;
    right: 3rem;
  }
  #resModal {
      z-index: 2222
  }
</style>